export default {
  gray: '#f4f4f4',
  gray_dark: '#333333',
  text: '#454545',
  text_light: '#b7b7b7',
  white: 'white',
  elements: '#bbbbbb',
  font: {
    size: {
      xxxs: '0.8rem',
      xxs: '1.1rem',
      xs: '1.14rem',
      s: '1.6rem',
      m: '2.6rem',
      l: '3.5rem',
      xl: '4.2rem',
    },
    family: {
      lato: 'Lato, sans-serif',
      oldStandard: "'Old Standard TT', serif",
    },
  },
  mq: {
    tablet: `@media (min-width: 768px)`,
    desktop: `@media (min-width: 1024px)`,
    large: `@media (min-width: 1440px)`,
  },
};
